<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h5>
          <i class="fa fa-server"></i>
          {{ lang('devices') }}
        </h5>
      </div>
      <div class="card-footer">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-sm">
            <thead>
              <th>
                {{ lang("user") }}
              </th>
              <th>
                {{ lang("title") }}
              </th>
              <th>
                {{ lang("serial-number") }}
              </th>
              <th>
                {{ lang("pending_records") }}
              </th>
              <th>
                {{ lang("exdate") }}
              </th>
              <th>
                Callback
              </th>
              <th>
                {{ lang("demo") }}
              </th>
              <th>
                {{ lang("last_active") }}
              </th>
            </thead>
            <tbody>
              <template v-for="(device, i) in devices">
                <tr v-if="i < viewlimit" :key="device._id" :class="device.last_active == 'connected-now' ? 'bg-light-success' : (device.last_active == 'not-connected-yet' ? 'bg-light-warning' : 'bg-light-danger')">
                  <td>
                    {{ device.user }}
                  </td>
                  <td>
                    {{ device.title }}
                  </td>
                  <td>
                    {{ device.serial_number }}
                  </td>
                  <td>
                    <span v-if="device.pending_records == 0">0</span>
                    <span v-if="device.pending_records > 0" class="text-danger">
                        <img :src="require('@/assets/images/loading.svg')" style="width: 16px;" alt="">
                        {{ device.pending_records }}
                        <u href="#" @click="deleteOrders(device.serial_number)"><i class="fa fa-times"></i></u>
                    </span>
                  </td>
                  <td>
                    <input type="date" v-model="device.subscribe.exdate" style="border: none; padding: 2px; border-radius: 5px" @change="changeDate(device.serial_number, device.subscribe.exdate)">
                  </td>
                  <td>
                    {{ device.subscribe.callback ? lang("yes") : lang("no") }}
                  </td>
                  <td>
                    {{ device.subscribe.demo ? lang("yes") : lang("no") }}
                  </td>
                  <td>
                    {{ lang(device.last_active) }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-12 text-center g" v-if="devices.length >= viewlimit">
          <button class="btn btn-primary btn-sm" @click="viewlimit = viewlimit + 50">
            <i class="fa fa-arrow-down"></i>
            {{ lang("load-more") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
      return {
        create: {timezone: '3'},
        user: JSON.parse(localStorage.getItem('user')),
        devices: [],
        current: {},
        from: new Date().toISOString().split("T")[0],
        to: new Date().toISOString().split("T")[0],
        interval: null,
        logs: [],
        users: [],
        selected: [],
        callbacks: [],
        frist: false,
        q: null,
        devices_obj: {},
        section_id: window?.location?.href?.split("section_id=")[1] ?? "",
        viewlimit: 50,
        sections: []
      }
    },
    beforeDestroy(){
      if(this.interval){
        clearInterval(this.interval)
      }
    },
    created(){
      var g = this;
      this.getDevices()
      this.interval = setInterval(() => {
        g.getDevices()
      }, 5000);
    },
    methods: {
      closeMeSearch(){
        $("#search___BV_modal_header_ > button").click()
      },
      changeDate(id, date){
        if(confirm(`متاكد من تغيير تاريخ الانتهاء الى ${date}؟`)){
          var g = this;
          $.post(api + '/admin/devices/change-exdate', {
            jwt: g.user.jwt,
            serial_number: id,
            date: date
          }).then(function(r){
            alert("تم بنجاح", 100)
          })
        }
      },
      getDevices(){
        var g = this;
        $.post(api + '/admin/devices/list', {
          jwt: g.user.jwt
        }).then(function(r){
          g.devices = r.response
        })
      },
    }
}
</script>

<style>

</style>
